// src/components/OurPartners.js
import React from 'react';
import './ourPartners.css';

function OurPartners() {
  const partners = [
    { name: 'SPACE TUTOR-ISRO', logo: require('../images/isro-logo.jpeg') },
    { name: 'NASA STEM GATEWAY', logo: require('../images/nasa.jpeg') },
    { name: 'IN-SPACe', logo: require('../images/in-space-logo.jpeg') },
    { name: 'Google Education', logo: require('../images/google-logo.jpeg') },
    { name: 'SANSA', logo: require('../images/sansa.jpg') },
    { name: 'ZINGSA', logo: require('../images/ZINGSA.jpeg') },
    { name: 'MS Education Consultants, DUBAI', logo: require('../images/MS Education.jpeg') },
    { name: 'KSA', logo: require('../images/ksa.jpeg') },
  ];

  return (
    <section className="partners-section">
      <h2>Global Collaborators</h2>
      <div className="partners-grid">
        {partners.map((partner, index) => (
          <div key={index} className="partner-card">
            <img src={partner.logo} alt={`${partner.name} Logo`} className="partner-logo" />
            <h3>{partner.name}</h3>
          </div>
        ))}
      </div>
    </section>
  );
}

export default OurPartners;
