import React from 'react';
import './accreditation.css'; 

// Importing images from the images folder
import cert1 from '../images/Certification1.jpg';  
import cert2 from '../images/Certification2.jpeg';  
import cert3 from '../images/Certificate3.jpeg';  
import cert4 from '../images/Certification4.jpeg';  

const Accreditation = () => {
  return (
    <div className="accreditation-container">
      <h2 className="accreditation-heading">Accreditation & Certifications</h2>
      <div className="accreditation-content">
        <p className="accreditation-description">
          Our organization is recognized by various reputable global societies and certification authorities. We uphold the highest standards of excellence in space exploration and education.
        </p>
        <div className="accreditation-cards">
          {/* Certificate Cards */}
          <div className="accreditation-card">
            <img src={cert1} alt="Certification 1" className="accreditation-image" />
          </div>
          <div className="accreditation-card">
            <img src={cert2} alt="Certification 2" className="accreditation-image" />
          </div>
          <div className="accreditation-card">
            <img src={cert3} alt="Certification 3" className="accreditation-image" />
          </div>
          <div className="accreditation-card">
            <img src={cert4} alt="Certification 4" className="accreditation-image" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accreditation;
