// src/App.js
// import React, { useState, useEffect } from 'react';
import {  Route, Routes, useLocation } from 'react-router-dom';
import Nav from './nav';
import Hero from './Hero';
import About from './basics/About';
import Olympiad from './Olympaid/Olympiad';
import OlympiadDetails from './Olympaid/OlympiadDetails';  // Import the new OlympiadDetails component
import OurPartners from './basics/OurPartners';
import OurEducationPartners from './basics/OurEducationPartners';
import UpcomingCourses from './basics/UpcomingCourses';
import FAQ from './basics/FAQ';
import Footer from './basics/Footer';
import RegistrationPage from './Lander/RegistrationPage';
import LoginPage from './Lander/LoginPage';
import OtpVerificationPage from './Lander/OtpVerificationPage';
import Testimonials from './basics/Testimonials';
import Gallery from './basics/Gallery';
import Accreditation from './basics/Accreditation'; 
import './App.css';

function App() {
  const location = useLocation();

  return (
    <div className="App">
      {/* Conditionally render the navbar based on the current route */}
      {location.pathname !== '/olympiad-details' && <Nav />}  {/* Only show Nav if not on OlympiadDetails */}

      <Routes>
        <Route
          path="/"
          element={
            <>
              <Hero />
              <About />
              <Olympiad />
              <OurPartners />
              <OurEducationPartners />
              <UpcomingCourses />
              <Gallery />
              <Testimonials />
              <Accreditation /> 
              <FAQ />
              <Footer />
            </>
          }
        />
        <Route path="/register" element={<RegistrationPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/otp-verification" element={<OtpVerificationPage />} />
        <Route path="/olympiad-details" element={<OlympiadDetails />} /> {/* Add route for the Olympiad details */}
      </Routes>
    </div>
  );
}

export default App;
