// src/components/UpcomingCourses.js
import React from 'react';
import './upcomingCourses.css';

const courses = [
  { title: "ANCIENT MATHS", image: require('../images/Vedic.jpeg') },
  { title: "SPACE SCIENCE", image: require('../images/Space.jpeg') },
  { title: "SKILL-SET FUSION", image: require('../images/robot.png') },
  // { title: "Machine Learning", image: require('../images/ML.jpeg') },
];

function UpcomingCourses() {
  return (
    <section className="upcoming-courses">
      <h2>Next-Gen Learning Paths</h2>
      <div className="courses-grid">
        {courses.map((course, index) => (
          <div key={index} className="course-card">
            <img src={course.image} alt={course.title} className="course-image" />
            <h3 className="course-title">{course.title}</h3>
            <p className="coming-soon">COMING SOON</p>
          </div>
        ))}
      </div>
    </section>
  );
}

export default UpcomingCourses;
