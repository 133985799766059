import React, { useState } from 'react';
import './otpVerification.css';
// import fetch from './helper';
import {useUserState} from '../helper';

function OTPVerificationPage() {
  const [Otp, setOtp] = useState(['', '', '', '']);
  const [isVerified, setIsVerified] = useState(false);
  const {fetch, user, setIsLoggedIn} = useUserState()
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const user = JSON.parse(localStorage.getItem('user'));
  // console.log("User: ", user)
  // console.log(user.email);
  // console.log(typeof(user))

  const handleChange = (index, value) => {
    if (/^[0-9]$/.test(value) || value === '') {
      const newOtp = [...Otp];
      newOtp[index] = value;
      setOtp(newOtp);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const otpCode = Otp.join('');
    // console.log('Entered OTP:', otpCode);
    // Submit OTP logic goes here
    //Axios to make the call to verify 
    try {
      const {data, status} = await fetch ({
	      url : 'verify-mail/',
        method : 'Put',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {email: user.email, otp: otpCode}
      });
      if (status === 200) {
        setIsVerified(true);
        setIsLoggedIn(true);
         // After successful OTP verification, set user info in localStorage
      localStorage.setItem('user', JSON.stringify(data.user_info));

      // Redirect to home page
      window.location.href = '/'; // Redirect to the main page (or home page)
      }
    } catch (error) {
      console.error("Error: ", error)
    }
  };
  const handleResend = async (e) => {
    e.preventDefault();
    try {
      
      const {data, status} = await fetch ({
        url : 'verify-mail/',
        method : 'Post',
        headers: {
          'Content-Type': 'application/json',
        },
        data : {email: user.email}

      })
    }catch (error){
      // console.error("Error", error);
    }
    }

  return (
    <div className="otp-verification-container">
      <h2>OTP Verification</h2>
      <p className="otp-instructions">Enter the 4-digit code sent to your email.</p>

      {!isVerified ? (
        <form onSubmit={handleSubmit}>
          <div className="otp-input-container">
            {Otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                className="otp-input"
                maxLength="1"
                value={digit}
                onChange={(e) => handleChange(index, e.target.value)}
                disabled={isSubmitting} // Disable input while submitting
              />
            ))}
          </div>
          <button type="submit" className="submit-btn" disabled={isSubmitting}>
            {isSubmitting ? 'Verifying...' : 'Verify'}
          </button>
        </form>
      ) : (
        <div className="success-message">
          <div className="tick-mark">✔</div>
          <p>An email with the login password has been sent.</p>
        </div>
      )}

      <button className="resend-link"  onClick=  {handleResend}>
        Resend OTP
      </button>
    </div>
  );
}

export default OTPVerificationPage;
