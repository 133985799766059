import React, { useState } from 'react';
import './login.css'; // Ensure your styles are imported
// import fetch from './helper';
import {useUserState} from '../helper';

function LoginForm() {
  const [formStatus, setFormStatus] = useState('')
  const {fetch} = useUserState();
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  }) 
  const [isLoading, setIsLoading] = useState(false); // Loading state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  // LoginForm.js
const handleSubmit = async (e) => {
  e.preventDefault();
  setIsLoading(true); // Show the spinner on submit
  
  try {
    const { data } = await fetch({
	    url: 'login/',
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: formData,
    });

    setFormStatus({ message: 'Login successful!', type: 'success' });
    console.log('Response:', data);

    // Save user info in localStorage after successful login
    localStorage.setItem('user', JSON.stringify(data.user_info));

    // Redirect to OTP verification page (if needed)
    // window.location.href = '/otp-verification';
  } catch (error) {
    setFormStatus({ message: `Error: ${error.message || error}`, type: 'error' });
    console.error('Error response:', error);
  }finally {
    setIsLoading(false); // Hide the spinner when the request is done
  }
};

  return (
    <form className="login-form" onSubmit={handleSubmit}>
      <h2>Login</h2>
      <label>Email:</label>
      <input 
        type="email" 
        name="email"
        value={formData.email} 
        onChange={handleChange} 
        required 
      />
      <label>Password:</label>
      <input 
        type="password"
        name='password'
        value={formData.password} 
        onChange={handleChange} 
        required 
      />
      <button type="submit" disabled={isLoading}>
        {isLoading ? (
          <>
            <span className="spinner"></span> Logging in...
          </>
        ) : (
          'Login'
        )}
      </button>
    </form>
  );
};

export default LoginForm;