// src/components/FAQ.js
import React, { useState, useRef } from 'react';
import './faq.css'; // Add styles for the FAQ section

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [height, setHeight] = useState("0px");
  const contentRef = useRef(null); // Reference for the content div

  const faqs = [
    {
      question: "What is Vedic Maths?",
      answer: "Vedic Maths is a collection of techniques based on ancient Indian scriptures that allow for quicker and more efficient calculations."
    },
    {
      question: "How can I enroll in the olympiads?",
      answer: "You can enroll in our courses by clicking the 'Register Now' and filling out the registration form."
    },
    {
      question: "Are the courses online or offline?",
      answer: "Our courses are primarily online, allowing you to learn at your own pace from anywhere in the world."
    },
    {
      question: "What age group is suitable for these courses?",
      answer: "Our courses are designed for learners of all ages, from young students to adults looking to enhance their skills."
    },
    {
      question: "Will I receive a certificate upon completion?",
      answer: "Yes, you will receive a certificate of completion for each course you finish successfully."
    },
  ];

  const toggleFAQ = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
      setHeight("0px"); // Collapse
    } else {
      setActiveIndex(index);
      setHeight(`${contentRef.current.scrollHeight}px`); // Expand
    }
  };

  return (
    <div className="faq-section">
      <h2>Frequently Asked Questions</h2>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div key={index} className={`faq-item ${activeIndex === index ? 'active' : ''}`}>
            <div className="faq-question" onClick={() => toggleFAQ(index)}>
              <h4>{faq.question}</h4>
              <span>{activeIndex === index ? '-' : '+'}</span>
            </div>
            <div
              className="faq-answer"
              ref={contentRef}
              style={{ maxHeight: activeIndex === index ? height : "0px" }} // Set max-height dynamically
            >
              <p>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
