import React, { useEffect, useState, createContext } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
// import { type } from '@testing-library/user-event/dist/type';

// Create context
const UserContext = createContext();

export const StateProvider = ({ children }) => {
  const navigate = useNavigate();
  // Axios fetch helper function
  const fetch = async ({
    method = 'GET',
    url = '',
    data = null,
    params = {},
    headers = {},
    responseType = 'json',
    timeout = 5000,
  }) => {
    try {
      const base_url = 'https://api.codimaths.com/';
      // const base_url = 'http://127.0.0.1:5000/';
      const fullUrl = `${base_url}${url}`;

      const response = await axios({
        method,
        url: fullUrl,
        data,
        params,
        headers,
        responseType,
        timeout,
      });
      return { data: response.data, status: response.status };
    } catch (error) {
      if (error.response) {
        console.error('Response Error:', error.response.data);
        throw error.response.data;
      } else if (error.request) {
        console.error('No Response Error:', error.request);
        throw new Error('No response from server');
      } else {
        console.error('Error:', error.message);
        throw error.message;
      }
    }
  };

  // Authentication state
  const [accessToken, setAccessToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  // Store authentication data in cookies
  const storeData = (formData) => {
    // console.log("formData: ", formData)
    if (formData.token.refresh && formData.token.access) {
      setAccessToken(formData.token.access)
      setRefreshToken(formData.token.refresh)
      Cookies.set("refreshToken", JSON.stringify(refreshToken), { expires: 14, secure: true });
      Cookies.set("accessToken", JSON.stringify(accessToken), { expires: 2, secure: true });
    } else {
      console.error("Error: Token(s) not found");
    }

    if (formData.user) {
      setUser(formData.user)
      const userData = JSON.stringify(formData.user);
      Cookies.set("user", userData, { expires: 14 });
    }
  };

  // Check authentication status from cookies
  useEffect(() => {
    // console.log("Effect runned");
    const checkAuthStatus = () => {
      const storedAccessToken = Cookies.get("accessToken");
      const storedRefreshToken = Cookies.get("refreshToken");
      const storedUserData = Cookies.get("user") ? JSON.parse(Cookies.get("user")) : null;

      if (storedUserData && storedAccessToken && storedRefreshToken) {
        // console.log("THis is true")
        setAccessToken(storedAccessToken);
        setRefreshToken(storedRefreshToken);
        setIsLoggedIn(true);
        setUser(storedUserData);
      } else {
        setAccessToken(null);
        setRefreshToken(null);
        setIsLoggedIn(false);
        setUser(null);
        // Optional: Redirect to login page if not authenticated
        // navigate("/");
      }
    };

    checkAuthStatus();
    const intervalId = setInterval(checkAuthStatus, 5000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [navigate]);

  // Logout function to clear authentication data
  const logout = () => {
    Cookies.remove("accessToken");
    Cookies.remove("refreshToken");
    Cookies.remove("user");

    setAccessToken(null);
    setRefreshToken(null);
    setIsLoggedIn(false);
    setUser(null);

    // Redirect to login page
    navigate("/");
  };

  return (
    <UserContext.Provider
      value={{
        accessToken,
        refreshToken,
        user,
        // isLoggedIn,
        isLoggedIn,
        setIsLoggedIn,
        logout,
        storeData,
        fetch,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use the context
export const useUserState = () => React.useContext(UserContext);
