import React, { useState } from 'react';
import './gallery.css';

// Sample images for student paintings (replace with actual image paths)
import painting1 from '../images/painting1.png';
import painting2 from '../images/painting2.png';
import painting3 from '../images/painting3.png';
import painting4 from '../images/painting4.png';
import painting5 from '../images/painting5.jpeg'; // New painting
import painting6 from '../images/painting6.jpeg'; // New painting
import painting7 from '../images/painting7.jpeg'; // New painting
import painting8 from '../images/painting8.jpeg'; // New painting

const Gallery = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null); // Holds the selected image URL

  // Gallery items with only images (no artist name)
  const galleryItems = [
    { image: painting1 },
    { image: painting2 },
    { image: painting3 },
    { image: painting4 },
    { image: painting5 }, 
    { image: painting6 }, 
    { image: painting7 },
    { image: painting8 } 
  ];

  const openModal = (image) => {
    setSelectedImage(image);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedImage(null);
  };

  // Function to handle sending the email
  const handleSendDrawing = () => {
    const subject = "Student Artwork Submission";
    const body = `Hello, I would like to submit my artwork for review. Please find the image attached.`;
    const mailtoLink = `mailto:info@codimaths.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink; // This opens the default email client
  };

  return (
    <div className="gallery-container">
      <h2 className="gallery-heading">To Infinity and Beyond: Kids' Art of Space</h2>
      <div className="gallery-grid">
        {galleryItems.map((item, index) => (
          <div key={index} className="gallery-item">
            <div className="card-container">
              <img
                src={item.image}
                alt={`Painting ${index + 1}`}
                className="gallery-image"
                onClick={() => openModal(item.image)} // Set the selected image on click
              />
            </div>
          </div>
        ))}
      </div>

      {/* Send Drawing Button */}
      <div className="send-drawing-container">
        <button
          className="send-button"
          onClick={handleSendDrawing}
        >
          Send Us Your Art
        </button>
      </div>

      {/* Modal view */}
      {modalIsOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img src={selectedImage} alt="Full-size painting" className="modal-image" />
            <button className="close-modal" onClick={closeModal}>×</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
