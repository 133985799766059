import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import './registrationForm.css';
import { useUserState } from '../helper';
import { useNavigate } from "react-router-dom";

function RegistrationForm() {
  const navigate = useNavigate();
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [formStatus, setFormStatus] = useState(null);
  const { storeData, fetch } = useUserState();
  const [formData, setFormData] = useState({
    first_name: '',
    lastName: '',
    age: '',
    phoneNumber: '',
    email: '',
    country: '',
  });

  // State to handle loading animation
  const [loading, setLoading] = useState(false);

  const handleCaptchaChange = (value) => {
    if (value) {
      setCaptchaVerified(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const isFormValid =
    formData.first_name &&
    formData.lastName &&
    formData.age &&
    formData.phoneNumber &&
    formData.email &&
    formData.country &&
    captchaVerified;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isFormValid) {
      setLoading(true); // Set loading to true when form submission starts
      try {
        const { data, status } = await fetch({
          url: 'register/',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          data: formData,
        });

        if (status === 201) {
          storeData(data);
          navigate('/otp-verification');
        }

        setFormStatus({ message: 'Registration successful!', type: 'success' });
      } catch (error) {
        setFormStatus({ message: `Error: ${error.message || error}`, type: 'error' });
      } finally {
        setLoading(false); // Set loading to false after the request finishes
      }
    }
  };

  return (
    <div className="registration-form-container">
      <h2>Register Now</h2>
      <form onSubmit={handleSubmit}>
        <label>First Name:</label>
        <input
          type="text"
          name="first_name"
          value={formData.first_name}
          onChange={handleChange}
          required
        />

        <label>Last Name:</label>
        <input
          type="text"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          required
        />

        <label>Age:</label>
        <input
          type="number"
          name="age"
          value={formData.age}
          onChange={handleChange}
          min="0"
          required
        />

        <label>Phone Number:</label>
        <input
          type="tel"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          required
        />

        <label>Email ID:</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />

        <label>Country:</label>
        <input
          type="text"
          name="country"
          value={formData.country}
          onChange={handleChange}
          required
        />

        <div className="captcha-container">
          <ReCAPTCHA
            sitekey="6Lf6VHAqAAAAALAIXhz5KAGCzyVJ051UEzx323XH"
            onChange={handleCaptchaChange}
          />
        </div>

        <div className="button-container">
          <button type="submit" className="submit-btn" disabled={!isFormValid || loading}>
            {loading ? (
              <>
                <div className="spinner"></div> {/* Show the spinner */}
                <span style={{ marginLeft: '10px' }}>Submitting...</span>
              </>
            ) : (
              'Submit'
            )}
          </button>
        </div>
      </form>
    </div>
  );
}

export default RegistrationForm;
