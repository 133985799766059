import React from 'react';
import './login.css'; // Make sure this path is correct
import LoginForm from './LoginForm'; // Import your LoginForm component

function LoginPage() {
  return (
    <div className="login-page">
      <LoginForm />
    </div>
  );
}

export default LoginPage;
