import React from 'react';
import './about.css'; // Import the CSS for styling
import 'font-awesome/css/font-awesome.min.css'; // Import Font Awesome CSS

const About = () => {
  return (
    <section id="about" className="about-section">
      <div className="about-content">
        <div className="about-box">
          <i className="fa fa-eye"></i> {/* New icon for Vision */}
          <h3>Our Vision</h3>
          <p>
            At CodiMaths, we aim to revive the treasure of ancient mathematics to create a bridge between traditional wisdom and modern education.
          </p>
        </div>
        <div className="about-box">
          <i className="fa fa-cogs"></i> {/* Icon for Mission */}
          <h3>Our Mission</h3>
          <p>
            Our mission is to address the challenges in mathematics and science learning, exacerbated by a mechanical education system and historical distortions.
          </p>
        </div>
        <div className="about-box">
          <i className="fa fa-book"></i> {/* New icon for Vedic Mathematics */}
          <h3>Ancient Mathematics</h3>
          <p>
            We believe that the unique techniques of Vedic Mathematics, introduced by Swami Bharathi Krishna Tirtha in the early 20th century, can rejuvenate students' mental abilities.
          </p>
        </div>
        <div className="about-box">
          <i className="fa fa-graduation-cap"></i> {/* Icon for Empowering Students */}
          <h3>Empowering Students</h3>
          <p>
            By applying 16 profound sutras rooted in Indian philosophy, we unlock the potential of the decimal system and empower students to approach mathematics with confidence and creativity.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
