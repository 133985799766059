import React from 'react';
import { useNavigate } from 'react-router-dom';
import RegistrationForm from './RegistrationForm';

function RegistrationPage() {
  const navigate = useNavigate();

  // Function to handle redirection to OTP page after successful registration
  const handleRegistrationSuccess = () => {
    navigate('/otp-verification');
  };

  return (
    <div>
      <RegistrationForm onRegistrationSuccess={handleRegistrationSuccess} />
    </div>
  );
}

export default RegistrationPage;
