import React, { useState, useEffect } from 'react';
import './hero.css';

// Import images
import Galaxy from './images/Galaxy.jpg';
import Space1 from './images/outer-space.jpg';
import Space2 from './images/sunrise.jpg';
import Space3 from './images/satellite.jpg';

function Hero() {
  // List of imported background images
  const images = [Galaxy, Space1, Space2, Space3];

  const [currentImage, setCurrentImage] = useState(0);
  const [nextImage, setNextImage] = useState(1);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentImage(nextImage);
      setNextImage((prevImage) => (prevImage + 1) % images.length); // Move to the next image
    }, 5000); // Change image every 5 seconds

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, [nextImage]);

  return (
    <section className="hero">
      <div className="hero-background">
        {/* Background images with opacity transition */}
        <div
          className="background-image current"
          style={{
            backgroundImage: `url(${images[currentImage]})`,
            transition: `0.3s ease-in-out`
          }}
        ></div>
        <div
          className="background-image next"
          style={{
            backgroundImage: `url(${images[nextImage]})`,
          }}
        ></div>
      </div>

      {/* Dark overlay */}
      <div className="hero-overlay"></div>

      {/* Hero content */}
      <div className="hero-content">
        <h1>
          EDUCATE, TRAIN AND <span style={{ color: "#5e9fe9", fontSize: "5.2rem", textShadow: "1px 1px 2px #fff" }}>EMPOWER</span>
        </h1>
        <p>
          We strive to build a future where knowledge and skills
          are accessible to everyone. Join us in the journey to unlock potential and
          transform the world through innovation and excellence.
        </p>

        {/* Button to navigate to the About section */}
        <a href="#about" className="explore-button">Inspiring Future Innovators</a>
      </div>
    </section>
  );
}

export default Hero;
