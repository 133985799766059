
import React from 'react';
import './ourEducationPartners.css';
import esaLogo from '../images/esa.png';     
import stemLogo from '../images/STEM.jpg'; 
import unicefLogo from '../images/UNICEF.png'; 

function OurEducationPartners() {
    return (
      <section className="education-partners-section">
        <h2>Our Education Collaborators</h2>
        <div className="education-partners-grid">
          <div className="education-card">
            <img src={esaLogo} alt="European Space Agency" className="education-logo esa" />
            <h3>European Space Agency</h3>
          </div>
          <div className="education-card">
            <img src={stemLogo} alt="STEM USA" className="education-logo" />
            <h3>STEM - USA</h3>
          </div>
          <div className="education-card">
            <img src={unicefLogo} alt="UNESCO" className="education-logo" />
            <h3>UNICEF</h3>
          </div>
        </div>
      </section>
    );
  }
  
  export default OurEducationPartners;