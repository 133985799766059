import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './hero.css'; 
import './Nav.css';
import { useUserState } from './helper';

function Nav() {
  // State to store the user information
  // const [user, setUser] = useState(null);
  const {user, logout, isLoggedIn} = useUserState();
  // console.log("Logged in?", isLoggedIn)
  // isLoggedIn = true;
  // // Check if user data exists in localStorage when the component mounts
  // useEffect(() => {
  //   const storedUser = localStorage.getItem('user');
  //   if (storedUser) {
  //     setUser(JSON.parse(storedUser)); // Set user data from localStorage
  //   }
  // }, []);

  // Logout handler to clear the user data from localStorage
  const handleLogout = () => {
    logout()
    // // localStorage.removeItem('user'); // Remove user data from localStorage
    // setUser(null); // Clear user state
    // window.location.href = '/'; // Redirect to home page after logout
  };

  return (
    <nav className="navbar">
      <Link className="navbar-logo" to="/">
        <img className='logologo' src={require('./images/logo.png')} alt="Logo" />
        <img className='logo-text' src={require('./images/codi-text.png')} alt="Logo-text" />
      </Link>
      <ul className="navbar-links">
        <li><a href="/">Home</a></li>
        <li><a href="#oly">Announcements</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="https://www.linkedin.com/company/codimaths/about/"target="_blank">Contact</a></li>
      </ul>

      {/* Login button */}
      {/* <div className="navbar-btn">
     
        {isLoggedIn ? (
          <>
            <span className="user-name">{user.name}</span>
            <button className="logout-btn" onClick={handleLogout}>Logout</button>
          </>
        ) : (
          <>
            <Link className="sign-up" to="/register">Register Now</Link>
            <Link className="login" to="/login">Login</Link>
          </>
        )}
      </div> */}
    </nav>
  );
}

export default Nav;
