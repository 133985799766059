import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './olympiad-details.css'; // Importing the updated CSS
import issoLogo from '../images/isso.png';
import { useUserState } from '../helper';
import { FaRocket, FaBrain, FaTrophy, FaChalkboardTeacher } from 'react-icons/fa'; // Font Awesome icons

function OlympiadDetails() {
  const {storeData, fetch} = useUserState();
  // const [alert, setAlert] = useState(null);
  const [formData, setFormData] = useState({
    full_name : '',
    first_name: '',
    last_name: '',
    age: '',
    phoneNumber: '',
    email: '',
    school: '',
    country: '',
    olympiad_id: 1
  });
  const navigate = useNavigate(); // For navigation after form submission

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Process the form (you can add further validation or send data to a server)
    try {
      const {data, status} = await fetch({
        url : "olympiad-registration/",
        method: "Post",
        headers: {
          'Content-Type': 'application/json',
        },
        data: formData,
      });

      
      if (status=== 201){
        storeData(data)
        navigate('/otp-verification');
      }else if(status === 208){
        storeData(data)
        alert("Successfully Registered!! All the Best!!")
        navigate('/thank-you')
      }
    }catch (error){
      alert("Failed to Register. Try Again")
      console.error(error);
    }
   
  };

  const handleFullName = (e) => {
    const FullName = e.target.value;

    const names = FullName.trim().split(" ");
    setFormData({
      ...formData,
      full_name : FullName,
      first_name: names[0],
      last_name : names.length > 1 ? names[names.length -1] : ''
    })
  }


  const handleFileOpen = async (fileId, event) => {
    event.preventDefault()
    try {
      
      
      const response = await fetch({url: `get_paper/?id=${fileId}`, 
                                    method: 'GET',
                                    responseType: 'blob',});
      if (response.status!==200) {
        alert("Couldn't Load File. Internal Server Issue! We are fixing this")
        throw new Error('Failed to fetch the file.');
      }
      if (response.status===200){
     
      const fileURL = window.URL.createObjectURL(response.data);

      // Open the file in a new tab
      window.open(fileURL, '_blank');}
    } catch (error) {
      console.error('Error fetching the file:', error);
      alert("Bad Request. We are Fixing this")
    }
  }

  return (
    <div className="olympiad-details-page">
      {/* Custom Navbar */}
      <nav className="olympiad-details-page__navbar">
        <div className="olympiad-details-page__logo">
          <img src={issoLogo} alt="ISSO Logo" />
        </div>
        <ul className="olympiad-details-page__navbar-links">
          <li><a href="/">Home</a></li> {/* Home Link */}
          <li><a href="#about">About the Olympiad</a></li> {/* Anchor to About Section */}
          <li><a href="#practice">Practice Papers</a></li> {/* Anchor to Practice Papers Section */}
          <li><a href="https://www.linkedin.com/company/codimaths/about/">Contact Us</a></li> {/* External Link */}
        </ul>
      </nav>

      {/* Registration Form Section */}
      <section className="olympiad-details-page__registration-section">
        <h2 className="olympiad-details-page__section-title">Olympiad Registration</h2>
        <form onSubmit={handleSubmit} className="olympiad-details-page__registration-form">
          <div className="form-group">
            <label htmlFor="name">Full Name</label>
            <input
              type="text"
              id="full_name"
              name="full_name"
              value={formData.full_name}
              onChange={handleFullName}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="age">Age</label>
            <input
              type="number"
              id="age"
              name="age"
              value={formData.age}
              onChange={handleChange}
              min="0" 
              onInput={(e) => {
              
                if (e.target.value < 0) {
                  e.target.value = 0;
                }
              }}
              required
            />
          </div>
          {/* <div className="form-group">
            <label htmlFor="age">Age</label>
            <input
              type="number"
              id="age"
              name="age"
              value={formData.age}
              onChange={handleChange}
              required
            />
          </div> */}
          <div className="form-group">
            <label htmlFor="phoneNumber">Phone Number</label>
            <input
              type="tel"
              id="phoneNumber"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
        
          <div className="form-group">
            <label htmlFor="country">Country</label>
            <input
              type="text"
              id="country"
              name="country"
              value={formData.country}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit" className="olympiad-details-page__register-btn">Register Now</button>
        </form>
      </section>

    {/* About Section */}
<section id="about" className="olympiad-details-page__about-section">
  <h2 className="olympiad-details-page__section-title">About the Olympiad</h2>
  <div className="olympiad-details-page__about-icons">
    <div className="olympiad-details-page__about-icon-box">
      <FaRocket className="icon" />
      <h3>Challenge Your Knowledge</h3>
      <p>Engage in rigorous problem-solving exercises to test your scientific and mathematical aptitude.</p>
    </div>
    <div className="olympiad-details-page__about-icon-box">
      <FaBrain className="icon" />
      <h3>Develop Critical Thinking</h3>
      <p>Enhance your problem-solving abilities and creative thinking while tackling complex challenges.</p>
    </div>
    <div className="olympiad-details-page__about-icon-box">
      <FaTrophy className="icon" />
      <h3>Achieve Excellence</h3>
      <p>Participate in a competitive environment and stand a chance to earn prestigious awards.</p>
    </div>
    <div className="olympiad-details-page__about-icon-box">
      <FaChalkboardTeacher className="icon" />
      <h3>Learn from Experts</h3>
      <p>Get insights from professionals and mentors who will guide you throughout the Olympiad.</p>
    </div>
  </div>
</section>


      {/* Practice Papers Section */}
      <section id="practice" className="olympiad-details-page__practice-section">
        <h2 className="olympiad-details-page__section-title">Practice Papers</h2>
        <p className="olympiad-details-page__section-text">Download the sample practice papers below to get started with your preparation:</p>
        <ul className="olympiad-details-page__practice-papers-list">
            {/* Add sample papers here here */}
          
          <li className="olympiad-details-page__practice-papers-list-item">
            <button className="link-style-button" onClick={(event) => handleFileOpen(5, event)} >Sample Paper 1 (PDF)</button>
          </li>
          <li className="olympiad-details-page__practice-papers-list-item">
            <button className="link-style-button" onClick={(event) => handleFileOpen(6, event)} >Sample Paper 2 (PDF)</button>
          </li>
          <li className="olympiad-details-page__practice-papers-list-item">
            <button className="link-style-button" onClick={(event) => handleFileOpen(7, event)} >Sample Paper 3 (PDF)</button>
          </li>
          <li className="olympiad-details-page__practice-papers-list-item">
            <button className="link-style-button" onClick={(event) => handleFileOpen(8, event)} >Sample Paper 4 (PDF)</button>
          </li>
          <li className="olympiad-details-page__practice-papers-list-item">
            <button className="link-style-button" onClick={(event) => handleFileOpen(9, event)} >Sample Paper 5 (PDF)</button>
          </li>
          <li className="olympiad-details-page__practice-papers-list-item">
            <button className="link-style-button" onClick={(event) => handleFileOpen(10, event)} >Sample Paper 6 (PDF)</button>
          </li>
          <li className="olympiad-details-page__practice-papers-list-item">
            <button className="link-style-button" onClick={(event) => handleFileOpen(11, event)} >Sample Paper 7 (PDF)</button>
          </li>
          <li className="olympiad-details-page__practice-papers-list-item">
            <button className="link-style-button" onClick={(event) => handleFileOpen(12, event)} >Sample Paper 8 (PDF)</button>
          </li>
          
        </ul>
      </section>
    </div>
  );
}

export default OlympiadDetails;
