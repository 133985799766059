import React from 'react';
import './testimonials.css';

// Import the images
import test1 from '../images/test1.jpeg';
import test2 from '../images/test2.jpeg';
import test3 from '../images/test3.jpeg';
import test4 from '../images/test4.jpeg'; 
import test5 from '../images/test5.jpeg';
import test6 from '../images/test6.jpeg'; 
import test7 from '../images/test7.jpeg'; 
import test8 from '../images/test8.png'; 
import test9 from '../images/test-9.jpeg'; 
import test10 from '../images/test10.jpeg'; 

const testimonials = [
  {
    name: 'Cnel. Gustavo Caubarrére',
    designation: '(Presidente de SIRGAS)', 
    image: test1,
    message: 'I support the one quality in astronaut more powerful than any other is curiosity. They have to get some place nobody’s ever been.',
  },
  {
    name: 'Mahantesh HireMath',
    designation: '(Former President of American Society of Mechanical Engineers Board)', 
    image: test2,
    message: 'I am all about spreading knowledge and excitement of Space Technology and Exploration. My mission is to- “Aspire to Inspire before You Expire.',
  },
  {
    name: 'Anil Pillai',
    designation: '(Co-Founder & Executive Director - BASE Group)', 
    image: test3,
    message: 'Space and time are the framework within which the mind is constrained to construct its experience of reality.',
  },
  {
    name: 'Nthabiseng Moloi',
    designation: '(Science Engagement Officer - STEM & Space Science Advocate | SANSA Space Agency)',
    image: test4,
    message: `Space science and technology is crucial for the future generation, especially in South Africa. At SANSA, we're committed to inspiring young minds and empowering communities.`,
  },
  {
    name: 'Rumbidzai Goto',
    designation: '(Head of Innovation Space Science Patron - Irene Christian College | Zimbabwe Space Agency)',
    image: test5,
    message: `Space Science education can help future generations to combat climate change challenges and create a sustainable future.`,
  },
  {
    name: 'Dan Goldin',
    designation: '(9th NASA Chief)',
    image: test6,
    message: 'Greatest civilization in history valued maths as a tool for survival and advancement',
  },
  {
    name: 'Miranda Satterthwaite',
    designation: '(Education Manager Aerospace New Zealand)',
    image: test7,
    message: 'Space Education is essential to train students for future roles in robotics , telecommunications',
  },
  {
    name: 'A R Murali Sankar',
    designation: '(Resident Expert , IN-SPACe)',
    image: test8, 
    message: `Space exploration drives technological advancements that benefit life on Earth, from improved navigation and weather forecasting to new medical innovations. It fosters global collaboration and inspires a deeper understanding of the universe.`,
  },
  // New testimonial for Maya Sherman
  {
    name: 'Maya Sherman',
    designation: '(Embassy of Israel in India | Innovation Attache , IFS)',
    image: test9, 
    message: `Engaging in space innovation, we contribute to a future where human ingenuity transcends borders, pushing the limits of what’s possible for all of humanity.`,
  },
  {
    name: 'Dr.Mariam (Currim) Shaikh',
    designation: ' Life Time Advocate for Education (CEO at MS Education Consultants DUBAI)',
    image: test10, 
    message: `I have always championed the power of education and curiosity and this initiative goes beyond a simple competition`,
  }
];

const Testimonials = () => {
  return (
    <div className="testimonials-container">
      <h2 className="testimonials-heading">Voices of Renowned Professionals</h2>
      <div className="testimonials-list">
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-card">
            <img 
              src={testimonial.image} 
              alt={testimonial.name} 
              className="testimonial-image" 
            />
            <p className="testimonial-message">"{testimonial.message}"</p>
            <p className="testimonial-name">{testimonial.name}</p>
            <p className="testimonial-designation">{testimonial.designation}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
