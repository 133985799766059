// src/components/Olympiad.js
import React from 'react';
import './olympiad.css';
import astronautImage from '../images/astronaut.png';
import studyImage from '../images/study.png';
import issoLogo from '../images/isso.png';
import { Link } from 'react-router-dom'; // Import Link for navigation

function Olympiad() {
  return (
    <section id='oly' className="olympiad-section">
      <img src={studyImage} alt="Study" className="study-image" />
      <div className="olympiad-content">
        <img src={issoLogo} alt="ISSO-logo" className='isso-logo' />
        <h2>
          Unlock Your Potential in the <span style={{ color: "#4a9cdb" }}>CodiMaths Olympiad !</span>
        </h2>
        <p className="ol-details-2">MAIDEN INTERNATIONAL SPACE SCIENCE OLYMPIAD</p>
        <p>
          Challenge yourself, sharpen your skills, and stand out as a math and science champion!
          Our Olympiad welcomes students from all over the world to participate in a range of exciting problems
          and puzzles designed to bring out the best in your mathematical and analytical abilities.
        </p>
        <ul className="benefits-list">
          <li>📈 Compete with top talent globally</li>
          <li>🏅 Earn awards, scholarships, and certificates</li>
          <li>🌍 Connect with renowned mentors and educators</li>
          <li>🚀 Step up to real-world challenges in STEM</li>
        </ul>

        {/* Updated button to link to the Olympiad details page using anchor */}
        <a href="/olympiad-details">
          <button className="olympiad-register-btn">Register Now</button>
        </a>

      </div>
      <img src={astronautImage} alt="Astronaut" className="astronaut" />
    </section>
  );
}

export default Olympiad;
